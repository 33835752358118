import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@app/services/base.service';
import { PagedResponse } from '@app/services/griddatasource.service';
import { environment } from '@env/environment';
import { catchError, map, Observable, shareReplay } from 'rxjs';
import { DeliveryLog, EmailOrPhone, PutVehicleBilling, ValidateCode, VehicleBilling } from '../models/vehicle-billing-model';

@Injectable({
  providedIn: 'root'
})
export class VehicleBillingService  extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getVehicleBilling(
    filter: string,
    page: number,
    pageSize: number,
  ): Observable<PagedResponse<VehicleBilling>> {
    return this.http
      .get<PagedResponse<VehicleBilling>>(
        `${
          environment.technicalDeliveryServerUrl
        }/vehicle-billings?Page=${page}&PageSize=${pageSize}&Filter=${filter}`
      )
      .pipe(map(super.extract), shareReplay(), catchError(super.serviceError));
  }

  sendWarningEmail(id: number, send: boolean){
    return this.http
    .get(`${environment.technicalDeliveryServerUrl}/vehicle-billings/WarningEmail/${id}/${send}`)
    .pipe(map(super.extract), catchError(super.serviceError));
  }

  getAssignedVehicleBilling(
    filter: string,
    page: number,
    pageSize: number,
    dealerId: string
  ): Observable<PagedResponse<VehicleBilling>> {
    return this.http
      .get<PagedResponse<VehicleBilling>>(
        `${
          environment.technicalDeliveryServerUrl
        }/vehicle-billings/getAssigned/${dealerId}?Page=${page}&PageSize=${pageSize}&Filter=${filter}`
      )
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getMyVehicleBilling(id: number): Observable<VehicleBilling[]> {
    return this.http
      .get<VehicleBilling[]>(
        `${
          environment.technicalDeliveryServerUrl
        }/vehicle-billings/getByUser/${id}`
      )
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getVehicleBillingById(
    id: number,
  ): Observable<VehicleBilling> {
    return this.http
      .get<PagedResponse<VehicleBilling>>(
        `${
          environment.technicalDeliveryServerUrl
        }/vehicle-billings/${id}`
      )
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  putVehicleBilling(id: number, vb: PutVehicleBilling) : Observable<VehicleBilling>{
    return this.http
      .put<VehicleBilling>(`${environment.technicalDeliveryServerUrl}/vehicle-billings/${id}`, vb)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  startDelivery(id: number, userId: number) : Observable<boolean>{
    return this.http
      .put<boolean>(`${environment.technicalDeliveryServerUrl}/vehicle-billings/start/${id}/${userId}`, null)
      .pipe(map(
        (response: any) => {
          return response['result'];
        }
      ), catchError(super.serviceError));
  }

  resumeDelivery(id: number) : Observable<boolean>{
    return this.http
      .put<boolean>(`${environment.technicalDeliveryServerUrl}/vehicle-billings/resume/${id}`, null)
      .pipe(map(
        (response: any) => {
          return response['result'];
        }
      ), catchError(super.serviceError));
  }

  unlinkDelivery(id: number) : Observable<boolean>{
    return this.http
      .put<boolean>(`${environment.technicalDeliveryServerUrl}/vehicle-billings/unlink/${id}`, null)
      .pipe(map(
        (response: any) => {
          return response['result'];
        }
      ), catchError(super.serviceError));
  }

  sendDelivery(id: number) : Observable<boolean>{
    return this.http
      .put<boolean>(`${environment.technicalDeliveryServerUrl}/vehicle-billings/send/${id}`, null)
      .pipe(map(
        (response: any) => {
          return response['result'];
        }
      ), catchError(super.serviceError));
  }

  reprocessDelivery(id: number) : Observable<boolean>{
    return this.http
      .put<boolean>(`${environment.technicalDeliveryServerUrl}/vehicle-billings/reprocess/${id}`, null)
      .pipe(map(
        (response: any) => {
          return response['result'];
        }
      ), catchError(super.serviceError));
  }

  deliveryVehicleBilling(body: DeliveryLog){
    return this.http
      .post(`${environment.technicalDeliveryServerUrl}/vehicle-billings/deliver`, body)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  sendEmailOrPhone(ep: EmailOrPhone) : Observable<EmailOrPhone>{
    return this.http
      .post<EmailOrPhone>(`${environment.technicalDeliveryServerUrl}/UserCode`, ep)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  validateCode(vc: ValidateCode) : Observable<ValidateCode>{
    return this.http
      .post<ValidateCode>(`${environment.technicalDeliveryServerUrl}/UserCode/validate`, vc)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  sendPdf(id: number, body: DeliveryLog){
    return this.http
      .post(`${environment.technicalDeliveryServerUrl}/Talao/${id}`, body)
      .pipe(map(super.extract), catchError(super.serviceError));
  }

  getPdf(id: number){
    return this.http
    .post(`${environment.technicalDeliveryServerUrl}/Talao/download/${id}`, null)
    .pipe(map(super.extract), catchError(super.serviceError));
  }

  getDeliveryLog(id: number){
    return this.http
    .get(`${environment.technicalDeliveryServerUrl}/vehicle-billings/deliveryLog/${id}`)
    .pipe(map(super.extract), catchError(super.serviceError));
  }

  getPendingSignatures(
    filter: string,
    page: number,
    pageSize: number,
    dealerId: string
  ): Observable<PagedResponse<VehicleBilling>>
  {
    return this.http
    .get(`${environment.technicalDeliveryServerUrl}/vehicle-billings/GetPending/${dealerId}?Page=${page}&PageSize=${pageSize}&Filter=${filter}`)
    .pipe(map(super.extract), shareReplay(),catchError(super.serviceError));
  }

  getBooklet(id: number, withLinked: boolean){
    return this.http
    .post(`${environment.technicalDeliveryServerUrl}/Talao/livreto/${id}?withLinked=${withLinked}`, null)
    .pipe(map(super.extract), catchError(super.serviceError));
  }

  getLinkedVehicle(id: number): Observable<VehicleBilling[]>{
    return this.http
    .get<VehicleBilling[]>(`${environment.technicalDeliveryServerUrl}/vehicle-billings/similar-vehicles/${id}`)
    .pipe(map(super.extract), catchError(super.serviceError));
  }
}
