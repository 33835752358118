import { Injectable } from '@angular/core';
import { I18nService } from '@app/i18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class I18nServicse {
  language: string;
  weekdays: Array<string>;
  weekLabel: Array<string>;
}

class I18nLanguages {
    weekdays: Array<string>;
    months: Array<string>;
    weekLabel: string;
}

const I18N_VALUES : Record<string, I18nLanguages> = {
  'pt-BR': {
    weekdays: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
    months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    weekLabel: 'Sem',
  },
  'en-US': {
    weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fry', 'Sat', 'Sun'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    weekLabel: 'Week',
  },
  'es-ES': {
    weekdays: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
    months: ['En', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    weekLabel: 'Sem',
  }
};

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18nService: I18nService) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this.i18nService.language].weekdays[weekday - 1];
  }
  getWeekLabel(): string {
    return I18N_VALUES[this.i18nService.language].weekLabel;
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18nService.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
