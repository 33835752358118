import { Component, EventEmitter,  Output, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { JsonPipe, CommonModule } from '@angular/common';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { IMasks } from '@app/shared/utils/input-masks';
import { CustomDatepickerI18n } from '@app/shared/utils/datepicker-i18n';
import { IMaskModule } from 'angular-imask';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-date-range-filter',
  standalone: true,
  imports: [NgbDatepickerModule, FormsModule, JsonPipe, NgbAccordionModule, TranslateModule, NgbTooltip, IMaskModule, CommonModule],
  templateUrl: './date-range-filter.component.html',
  providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
  styleUrls: ['./date-range-filter.component.scss'],
})

export class DateRangeFilterComponent {
  @ViewChild('accordionItem', { static: true }) accordionItem: any;
  @Output() from = new EventEmitter<NgbDate | null>();
  @Output() to = new EventEmitter<NgbDate | null>();
  @Output() clearTo = new EventEmitter<NgbDate | null>();

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  dateMask: any = IMasks.dateMask;

  @ViewChild(MultiSelectComponent) dropdown: MultiSelectComponent;

  dropdownSettings: IDropdownSettings;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {}

  opened: boolean = false;

  isRotated = false;

  toggleRotate() {
    this.isRotated = !this.isRotated;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.from.emit(this.fromDate);
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      this.to.emit(this.toDate);
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.clearTo.emit(this.fromDate);
    }
  }

  emitFromDate() {
    let stringLength = `${this.fromDate?.year}-${this.fromDate?.month}-${this.fromDate?.day}`?.length
    if(stringLength < 10){
      return
    }

    this.from.emit(this.fromDate);
  }
  emitToDate() {
    let stringLength = `${this.toDate?.year}-${this.toDate?.month}-${this.toDate?.day}`?.length
    if(stringLength < 10){
      return
    }
    this.to.emit(this.toDate);
  }
  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  clearDateFilter() {
    this.toDate = null;
    this.fromDate = null;

    this.from.emit(this.fromDate);
    this.to.emit(this.toDate);
  }
}
