<div ngbAccordion>
  <div ngbAccordionItem id="'date-range-filter" [collapsed]="true">
      <div class="custom-style" tourAnchor="date.range.filter">
        <label for="search-period" class="dropdown-label">
          {{ 'Search for period' | translate }}
        </label>
        <button 
          ngbAccordionButton
          class="dropdown-multiselect__caret btn small-button button-style"
          [ngClass]="{'rotate': isRotated}"
          (click)="toggleRotate()">
        </button>
      </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
      <ng-template ngbPanelContent>
        <div class="input-group">
          <input
            style="display: none"
            name="datepicker"
            class="form-control mt-4"
            ngbDatepicker
            [imask]="dateMask"
            #datepicker="ngbDatepicker"
            [placement]="'right'"
            [positionTarget]="buttonEl"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event); datepicker.toggle()"
            [displayMonths]="2"
            [dayTemplate]="t"
            outsideDays="hidden"
            [startDate]="fromDate!"
            tabindex="-1"
          />
          <ng-template #t let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>
        </div>
        <div class="input-group mb-2">
          <input
            [imask]="dateMask"
            (keyup.enter)="emitFromDate()"
            (focusout)="emitFromDate()"
            type="text"
            #dpFromDate
            class="form-control date-filter"
            [placeholder]="'From Placeholder'|translate"
            name="dpFromDate"
            [value]="formatter.format(fromDate)"
            (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
            [ngbTooltip]="'From Tooltip'|translate"
            placement="top"
          />
          <div class="input-group-append">
            <button #buttonEl class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
              <i class="fas fa-calendar-alt"></i>
            </button>
          </div>
        </div>
  
        <div class="input-group">
          <input
            type="text"
            [imask]="dateMask"
            #dpToDate
            class="form-control date-filter"
            [placeholder]="'To Placeholder'|translate"
            name="dpToDate"
            [value]="formatter.format(toDate)"
            (input)="toDate = validateInput(toDate, dpToDate.value)"
            [ngbTooltip]="'To Tooltip'|translate"
            placement="top"
            (focusout)="emitToDate()"
            (keyup.enter)="emitToDate()"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
              <i class="fas fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <div class="input-group mt-2">
          <button
            class="btn btn-outline-secondary"
            (click)="clearDateFilter()"
            type="button"
            [ngbTooltip]="'Clear Filter'|translate"
            placement="top"
          >
            <i class="fas fa-eraser"></i>
          </button>
        </div>
      </ng-template>
    </div>
    </div>
  </div>
</div>