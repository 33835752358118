import { CountryCodes } from "./countries-of-operation.enum";
export class IMasks{
  static dateMask = {
    mask: Date,
    pattern: 'd{/}`m{/}`Y',
    format: function (date:any) {
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();

      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;

      return [day, month, year].join('/');
    },
    parse: function (str: any) {
      var yearMonthDay = str.split('/');
      var date = new Date(yearMonthDay[2], yearMonthDay[1] - 1, yearMonthDay[0])
      return date;
    },
    lazy: false,
  }
  static otherCountriesMask = {mask: [{mask: '**********' }]};
  static plateMask = {mask:[ {mask: 'aaa0000'}, {mask: 'aaa0a00' }]};
  static chilePlateMask =  {mask:[ {mask: 'aaaa00'}, {mask: 'aa0000'}]};
  static peruPlateMask = {mask: [{mask: '**********' }]};
  static argentinaPlateMask = {mask: [{mask: 'aaa000'}, {mask: 'aa000aa'}]};
  static colombiaPlateMask = {mask: [{mask: 'aaa000'}]};
  static nameMask = { mask: /^(\w+\s?)*\s*$/ };
  static phoneMask = {mask: [{mask:'(00)00000-0000'}, {mask:'(00)0000-0000'}, {mask:'00 000-000-000'}, {mask:'00 (00) 000-000'},{mask:'000000000000000'}]};
  static rgMask ={mask: [{mask: '00.000.000-0'}, {mask: '0.000.000-0'}]}
  static chassiMask = {mask: [{mask:'000000'}]};
  static chassisAndSeriesMask = {mask: [{mask:'***** 000000'}]};
  static vinNumberMask = {mask: [{mask:'*****************'}]};
  static last7DigitsFromVinNumberMask = {mask: [{mask:'a000000'}]};
  static thousandsMask = {
    mask: Number,
    scale: 0,
    thousandsSeparator: '.'
  };
  static decimalMask = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    radix: ',',
    padFractionalZeros: true,
    normalizeZeros: true,
    min: 0,
    max: 999999999.99
  };
  static taxIdMask = {mask: [{mask: '000.000.000-00'}, {mask: '**.***.***/****-00'}]};
}

export function GetCountryPlateMask(countryCode: string): string|any{
  switch (countryCode) {
    case CountryCodes.Brasil:
      return IMasks.plateMask
    case CountryCodes.Chile:
      return IMasks.chilePlateMask
    case CountryCodes["Not Licensed"]:
      return IMasks.chassiMask
    case CountryCodes.Peru:
      return IMasks.peruPlateMask
    case CountryCodes.Argentina:
      return IMasks.argentinaPlateMask
    case CountryCodes.Colombia:
      return IMasks.colombiaPlateMask
    default:
      return IMasks.otherCountriesMask
  }
}
